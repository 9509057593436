import React from 'react';
import {Button, Form} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import TextInputSection from '../../../public/js/components/TextInputSection';
import HttpRequestUtil from '../../../public/js/util/HttpRequestUtil';

const emptyRequest = { message: '' };

class RequestInfoSection extends React.Component {
    constructor(props) {
        super(props);

        var initialRequest = Object.assign({}, emptyRequest);

        this.state = { request: initialRequest, sendError: false };
    }

    componentDidMount() {
        
    }

    handleRequestChange(key, e) {
		e.preventDefault();
		var request = this.state.request;
		request[key] = e.target.value;
		this.setState({ request: request});
	}

	requestInfo(e) {
        e.preventDefault();

        this.setState({error: false});

        var companyData = this.props.company;
        var requestData = this.state.request;
        var userData = this.props.user;

        requestData.contactEmail = userData.username;

        var jsonData = JSON.stringify(requestData);
        
        //TODO set and show some kind of error text on failure?
        HttpRequestUtil.post('../api/admin/requestinfo/' + companyData.uid, jsonData, this.props.sendSuccess.bind(this), this.setSendError.bind(this));
    }

    setSendError() {
        this.setState({sendError: true});
    }

    setError() {
        this.setState({error: true});
    }

    render () {
		const {
			company
		} = this.props;

		var {
			sendError,
			request
		} = this.state;

		return (
			<div className="top-margin">
				<h6 className='bottom-margin-small caps'><FormattedMessage id='requestForMoreInformation' /></h6>
        		<Form className='text-start'>
        			<TextInputSection area className="message-input" sectionName='message' value={request.message} handleChange={this.handleRequestChange.bind(this)} maxLength={500} placeholder='placeholderMessage' />
    				<Button block color='primary' disabled={!request.message} onClick={this.requestInfo.bind(this)}> 
		        		<FormattedMessage id='send' />
		        	</Button>
                    { sendError ? <span className="text-danger"><FormattedMessage id='errorSendingInformation' /></span> : null }
        		</Form>
            </div>
		);
	}

}

export default RequestInfoSection;
