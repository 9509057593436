import React from 'react';
import Timestamp from 'react-timestamp';
import {Card, CardText, Nav, NavItem, NavLink, TabContent, Table, TabPane} from 'reactstrap';
import {FormattedMessage, injectIntl} from 'react-intl';
import HttpRequestUtil from '../../../public/js/util/HttpRequestUtil';
import classnames from 'classnames';

class InnoList extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = { pendingCompanies: [ ], acceptedCompanies: [ ], rejectedCompanies: [ ], pendingUsers: [ ], activeTab: '1', lastOrderedBy: '' };
    }

    componentDidMount() {
        this.refreshData();
        this.resultStart.scrollIntoView({behavior: "smooth"});
    }

    refreshData() {
        this.getCompanies();
        this.getPendingUsers();
    }

    getCompanies() {
        var data = '{"inno": true}';
        HttpRequestUtil.post('../api/companies', data,this.setCompanies.bind(this), this.setError.bind(this));
    }

    getPendingUsers() {
        HttpRequestUtil.get('../api/admin/pendingusers', this.setUsers.bind(this), this.setError.bind(this));
    }

    setCompanies(data) {
        var companies = data;
        if (companies && companies.length > 0) {
            var pending = companies.filter((c) => { return !c.accepted && !c.acceptedTime });
            var rejected = companies.filter((c) => { return !c.accepted && c.acceptedTime });
            var accepted = companies.filter((c) => { return c.accepted });
            this.setState({pendingCompanies: pending, rejectedCompanies: rejected, acceptedCompanies: accepted });
        }
    }

    setUsers(data) {
        var users = data;
        if (users && users.length > 0) {
            this.setState({ pendingUsers: users });
        }
    }

    setError() {
        this.setState({loading: false});
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                lastOrderedBy: '',
                activeTab: tab
            });
        }
    }

    renderTimeStamp(time) {
        if (time) {
        const date = new Date(time);
        return <Timestamp date={date} />;
        }
    }

    reorderList(type, list) {

        if (type === this.state.lastOrderedBy) {
            list.reverse();
            return list;
        }

        if (type === 'modified' || type === 'created' || type === 'acceptedTime' || type === 'infoRequestedTime') {
            list.sort((company1, company2) => (new Date(company2[type]) - new Date(company1[type])));
        } else if (type === 'id') { 
            list.sort((company1, company2) => {
                return company1[type] - company2[type];
            });
        } else {
            list.sort((company1, company2) => {
                var string1 = company1[type].toLowerCase();
                var string2 = company2[type].toLowerCase();
                if (string1 < string2) {
                    return -1;
                } else if (string1 > string2) {
                    return 1;
                }
                return 0;
            });
        }

        this.setState({lastOrderedBy: type});

        return list;
    }

    reorderApproved(orderingType) {
        var approved = this.reorderList(orderingType, this.state.acceptedCompanies);
        this.setState({ acceptedCompanies: approved });
    }

    reorderRejected(orderingType) {
        var rejected = this.reorderList(orderingType, this.state.rejectedCompanies);
        this.setState({ rejectedCompanies: rejected });
    }

    reorderPending(orderingType) {
        var pending = this.reorderList(orderingType, this.state.pendingCompanies);
        this.setState({ pendingCompanies: pending });
    }

    reorderPendingUsers(orderingType) {
        var pending = this.reorderList(orderingType, this.state.pendingUsers);
        this.setState({ pendingUsers: pending });
    }

    listCompanies(companies) {
        if (!companies || companies.length === 0) {
            return (<Card>
                        <CardText>
                            <div className="top-margin bottom-margin">
                                <FormattedMessage id='noCompaniesFound' />
                            </div>
                        </CardText>
                    </Card>);
        }
        return (<Table className='text-start'>
                    <thead>
                    <tr>
                        <th onClick={this.reorderPending.bind(this, 'name')} className='column-header-link'>
                            <FormattedMessage id='columnName' />
                        </th>
                        <th onClick={this.reorderPending.bind(this, 'created')} className='column-header-link'>
                            <FormattedMessage id='columnSubmitted' />
                        </th>
                        <th onClick={this.reorderPending.bind(this, 'modified')} className='column-header-link'>
                            <FormattedMessage id='columnLastEdit' />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    { companies.map((company, i) => {

                        return (<tr key={i}>
                                    <td><a href={"#/" + company.uid}>{company.name}</a></td>
                                    <td>{ this.renderTimeStamp(company.created) }</td>
                                    <td>{ this.renderTimeStamp(company.modified) }</td>
                                </tr>
                        );
                    })}
                    </tbody>
                </Table>);
    }

    listApprovedCompanies(companies) {
        if (!companies || companies.length === 0) {
            return (<Card>
                        <CardText>
                            <div className="top-margin bottom-margin">
                                <FormattedMessage id='noCompaniesFound' />
                            </div>
                        </CardText>
                    </Card>);
        }
        return (<Table className='text-start'>
                    <thead>
                    <tr>
                        <th onClick={this.reorderApproved.bind(this, 'name')} className='column-header-link'>
                            <FormattedMessage id='columnName' />
                        </th>
                        <th onClick={this.reorderApproved.bind(this, 'created')} className='column-header-link'>
                            <FormattedMessage id='columnSubmitted' />
                        </th>
                        <th onClick={this.reorderApproved.bind(this, 'modified')} className='column-header-link'>
                            <FormattedMessage id='columnLastEdit' />
                        </th>
                        <th onClick={this.reorderApproved.bind(this, 'acceptedTime')} className='column-header-link'>
                            <FormattedMessage id='columnApproved' />
                        </th>
                        <th onClick={this.reorderApproved.bind(this, 'acceptedBy')} className='column-header-link'>
                            <FormattedMessage id='columnApprovedBy' />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    { companies.map((company, i) => {

                        return (<tr key={i}>
                                    <td><a href={"#/" + company.uid}>{company.name}</a></td>
                                    <td>{ this.renderTimeStamp(company.created) }</td>
                                    <td>{ this.renderTimeStamp(company.modified) }</td>
                                    <td>{ this.renderTimeStamp(company.acceptedTime) }</td>
                                    <td>{company.acceptedBy}</td>
                                </tr>
                        );
                    })}
                    </tbody>
                </Table>);
    }

    listRejectedCompanies(companies) {
        if (!companies || companies.length === 0) {
            return (<Card>
                        <CardText>
                            <div className="top-margin bottom-margin">
                                <FormattedMessage id='noCompaniesFound' />
                            </div>
                        </CardText>
                    </Card>);
        }
        return (<Table className='text-start'>
                    <thead>
                    <tr>
                        <th onClick={this.reorderRejected.bind(this, 'name')} className='column-header-link'>
                            <FormattedMessage id='columnName' />
                        </th>
                        <th onClick={this.reorderRejected.bind(this, 'created')} className='column-header-link'>
                            <FormattedMessage id='columnSubmitted' />
                        </th>
                        <th onClick={this.reorderRejected.bind(this, 'modified')} className='column-header-link'>
                            <FormattedMessage id='columnLastEdit' />
                        </th>
                        <th onClick={this.reorderRejected.bind(this, 'acceptedTime')} className='column-header-link'>
                            <FormattedMessage id='columnRejected' />
                        </th>
                        <th onClick={this.reorderRejected.bind(this, 'acceptedBy')} className='column-header-link'>
                            <FormattedMessage id='columnRejectedBy' />
                        </th>
                        <th onClick={this.reorderRejected.bind(this, 'infoRequestedTime')} className='column-header-link'>
                            <FormattedMessage id='columnInfoRequested' />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    { companies.map((company, i) => {

                        return (<tr key={i}>
                                    <td><a href={"#/" + company.uid}>{company.name}</a></td>
                                    <td>{ this.renderTimeStamp(company.created) }</td>
                                    <td>{ this.renderTimeStamp(company.modified) }</td>
                                    <td>{ this.renderTimeStamp(company.acceptedTime) }</td>
                                    <td>{company.acceptedBy}</td>
                                    <td>{ this.renderTimeStamp(company.infoRequestedTime) }</td>
                                </tr>
                        );
                    })}
                    </tbody>
                </Table>);
    }

    listPendingUsers(users) {
        if (!users || users.length === 0) {
            return (<Card>
                        <CardText>
                            <div className="top-margin bottom-margin">
                                <FormattedMessage id='noUsersFound' />
                            </div>
                        </CardText>
                    </Card>);
        }
        return (<Table className='text-start'>
                    <thead>
                    <tr>
                        <th onClick={this.reorderPendingUsers.bind(this, 'id')} className='column-header-link'>
                            #
                        </th>
                        <th onClick={this.reorderPendingUsers.bind(this, 'email')} className='column-header-link'>
                            <FormattedMessage id='columnEmail' />
                        </th>
                        <th>
                            <FormattedMessage id='columnLink' />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    { users.map((user, i) => {

                        return (<tr key={i}>
                                    <td>{user.id}</td>
                                    <td>{user.email}</td>
                                    <td><a href={"../#/register/" + user.uid}>{user.uid}</a></td>
                                </tr>
                        );
                    })}
                    </tbody>
                </Table>);
    }

    render() {
        const pendingCompanies = this.state.pendingCompanies;
        const acceptedCompanies = this.state.acceptedCompanies;
        const rejectedCompanies = this.state.rejectedCompanies;
        const pendingUsers = this.state.pendingUsers;

        return (
            
            <div className='text-center mt-4 px-3 wide-limit-width-and-center bottom-margin'>
            <div style={ {float:"left", clear: "both"} } ref={(scrollertop) => { this.resultStart = scrollertop; }}></div>
                <div className='bottom-margin text-start'>
                    <h1 className='bottom-margin-small'> <FormattedMessage id='administrationInno' /> </h1>
                </div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => { this.toggle('1'); }}
                        >
                            <FormattedMessage id='listPendingInnoCompanies' />
                            &nbsp;({pendingCompanies.length})
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.toggle('2'); }}
                        >
                            <FormattedMessage id='listApprovedInnoCompanies' />
                            &nbsp;({acceptedCompanies.length})
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => { this.toggle('3'); }}
                        >
                            <FormattedMessage id='listRejectedInnoCompanies' />
                            &nbsp;({rejectedCompanies.length})
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '4' })}
                          onClick={() => { this.toggle('4'); }}
                        >
                            <FormattedMessage id='listPendingUsers' />
                            &nbsp;({pendingUsers.length})
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        { this.listCompanies(pendingCompanies) }
                    </TabPane>
                    <TabPane tabId="2">
                        { this.listApprovedCompanies(acceptedCompanies) }
                    </TabPane>
                    <TabPane tabId="3">
                        { this.listRejectedCompanies(rejectedCompanies) }
                    </TabPane>
                    <TabPane tabId="4">
                        { this.listPendingUsers(pendingUsers) }
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}

export default injectIntl(InnoList);
