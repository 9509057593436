import React from 'react';
import {Button} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import {RouterHOC} from "../../../public/js/components/RouterHOC";

class Administration extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        console.log("admin mounted")
    }

    handleInnoClick() {
        this.props.navigate('/adminInno');
    }

    handleESClick() {
        this.props.navigate('/adminES');
    }

    render() {
        return (
            <div>
                <div className='text-center px-2 mt-4 limit-width-and-center'>
                    <div name='form-header' className='bottom-margin'>
                        <h1 className='bottom-margin-small'> <FormattedMessage id='administration' /> </h1>
                    </div>
                    <Button block onClick={this.handleESClick.bind(this)} className='mb-2'><FormattedMessage id='ESAdminButton' /></Button>
                    <Button block onClick={this.handleInnoClick.bind(this)}><FormattedMessage id='innoAdminButton' /></Button>
                </div>
            </div>
        );
    }
}

export default RouterHOC(Administration);