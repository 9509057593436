import React from 'react';
import {Container} from 'reactstrap';
import Header from './Header.jsx';
import Footer from '../../../public/js/components/Footer.jsx';

class App extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Container fluid className='mx-0 px-0'>
				<header className='nav-header'>
					<Header />
				</header>

				<main>
					<Container fluid className='mx-0 px-0'>
						{this.props.children}
					</Container>
				</main>

				<footer>
					<Footer />
				</footer>
			</Container>
		)
	}

}

export default App;