import {base64StringToBlob} from 'blob-util';
import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {
  Button,
  Card,
  CardBody,
  CardDeck,
  CardImg,
  CardText,
  CardTitle,
  Col,
  FormText,
  Input,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
} from 'reactstrap';
import HttpRequestUtil from '../../../public/js/util/HttpRequestUtil';
import RequestInfoSection from './RequestInfoSection';
import FormattedMessageHOC from "../../../public/js/components/FormattedMessageHOC";
import _ from "lodash";
import {RouterHOC} from "../../../public/js/components/RouterHOC";

const emptyRequest = {
  contactName: null,
  companyName: null,
  contactEmail: null,
  message: '',
};

class CompanyView extends React.Component {
  constructor(props) {
    super(props);

    this.deletePopoverRef = React.createRef();
    this.handleClickOutsideRef = this.handleClickOutsideRef.bind(this);

    var initialRequest = Object.assign({}, emptyRequest);

    this.state = {
      company: {},
      loading: false,
      evaluated: false,
      teamFinland: false,
      rejected: false,
      logo: '',
      user: {},
      admin: {},
      contactPersonPhotos: [],
      request: initialRequest,
      deletePopoverOpen: false,
    };
  }

  handleClickOutsideRef(event) {
    if (this.state.deletePopoverOpen && this.deletePopoverRef.current && !this.deletePopoverRef.current.contains(event.target)) {
      this.toggleDelete();
    }
  }

  componentDidMount() {
    this.refreshData();
    // this.getCurrentUserId();
    this.resultStart.scrollIntoView({behavior: 'smooth'});
    document.addEventListener('click', this.handleClickOutsideRef, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutsideRef, true);
  }

  toggleDelete() {
    this.setState({
      deletePopoverOpen: !this.state.deletePopoverOpen,
    });
  }

  refreshData() {
    var uid = this.props.params.id;
    this.getCompany(uid);
    this.getCompanyUser(uid);
  }

  getCompany(uid) {
    this.setState({loading: true});
    HttpRequestUtil.get(
      '../api/company/' + uid,
      this.setCompany.bind(this),
      this.setError.bind(this)
    );
  }

  getCompanyUser(uid) {
    this.setState({loading: true});
    HttpRequestUtil.get(
      '../api/company/' + uid + '/user',
      this.setCompanyUser.bind(this),
      this.setError.bind(this)
    );
  }

  // getCurrentUserId() {
  //     HttpRequestUtil.get('../luvittaja/api/user', this.getCurrentUser.bind(this), this.setError.bind(this));
  // }

  // getCurrentUser(data) {
  //     if (data && data.legalpersonid) {
  //         HttpRequestUtil.get('../luvittaja/api/legalperson/' + data.legalpersonid + '/accounts', this.setCurrentUser.bind(this), this.setError.bind(this));
  //     }
  // }

  // setCurrentUser(data) {
  //     this.setState({ admin: data[0] });
  // }

  setCompany(data) {
    // evaluated, teamFinland fields are no longer in use, which is why the value is always set to FALSE
    this.setState({
      company: data[0],
      evaluated: false,
      teamFinland: false,
      loading: false,
    });

    this.parseLogoImg(data[0].logo);
    this.parseContactPersonImg(data[0]);
  }

  setCompanyUser(data) {
    this.setState({user: data[0]});
  }

  parseLogoImg(baseImg) {
    if (!baseImg) {
      return;
    }
    this.setState({ logo: URL.createObjectURL(base64StringToBlob(baseImg)) });
  }

  parseContactPersonImg(company) {
    if (!company || !company.experts || company.experts.length === 0) {
      return;
    }

    company.experts.map((expert) => {
      const baseImg = expert.photo;
      if (baseImg) {
        const blobUrl = URL.createObjectURL(base64StringToBlob(baseImg));
        let photos = this.state.contactPersonPhotos.slice();
        photos.push(blobUrl);
        this.setState({ contactPersonPhotos: photos });
      }
    })
  }

  setError() {
    this.setState({loading: false});
  }

  checkCheckboxes(data) {
    this.acceptReturn();
  }

  approveCompany(company) {
    var jsonData = JSON.stringify({
      uid: company.uid,
      acceptedBy: this.state.admin ? this.state.admin.username : null,
    });
    HttpRequestUtil.post(
      '../api/admin/approve',
      jsonData,
      this.checkCheckboxes.bind(this),
      () => {}
    );
  }

  rejectCompany(company) {
    var jsonData = JSON.stringify({
      uid: company.uid,
      acceptedBy: this.state.admin ? this.state.admin.username : null,
    });
    HttpRequestUtil.post(
      '../api/admin/reject',
      jsonData,
      this.checkCheckboxes.bind(this),
      () => {}
    );
  }

  deleteCompany(company) {
    HttpRequestUtil.delete(
      '../api/admin/company/' + company.uid,
      this.redirectToList.bind(this),
      this.redirectToList.bind(this)
    );
  }

  redirectToList() {
    this.props.navigate('/adminES');
  }

  renderSelections(selections) {
    if (!selections || selections.length === 0) {
      return;
    }
    return (
      <div>
        {selections.map((item, i) => {
          if (item.length > 0) {
            return (
              <span key={i} className="right-margin">
                <FormattedMessageHOC {...{id: item}} />
              </span>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }

  renderContactPersons(experts) {
    if (!experts || experts.length === 0) {
      return;
    }

    var photos = this.state.contactPersonPhotos;

    return (
      <CardDeck>
        {experts.map((expert, i) => {
          return (
            <Card className="expCard" key={i}>
              {photos[i] ? (
                <CardImg
                  top
                  style={{maxWidth: '300px', maxHeight: '300px'}}
                  src={photos[i]}
                />
              ) : (
                ''
              )}
              <CardBody>
                <CardTitle>{expert.name}</CardTitle>
                <CardText>
                  {expert.title}
                  <br />
                  {expert.phone}
                  <br />
                  {expert.email}
                  <br />
                  {expert.link ? (
                    <a href={expert.link} target="_blank">
                      <FormattedMessage id="link" />
                    </a>
                  ) : null}
                </CardText>
              </CardBody>
            </Card>
          );
        })}
      </CardDeck>
    );
  }

  renderPublications(links) {
    if (!links || links.length === 0) {
      return;
    }

    return (
      <div>
        {links.map((link, i) => {
          return (
            <div key={i} className="right-margin">
              <a href={link} target="_blank">
                {link}
              </a>
            </div>
          );
        })}
      </div>
    );
  }

  renderFiles(files, type) {
    if (!files || files.length === 0) {
      return;
    }

    return (
      <div>
        {files.map((file, i) => {
          return (
            <div key={i} className="right-margin">
              <i
                className="icon ion-document right-margin-small"
                key={'fileicon' + i}
              />
              <a
                download={file.name}
                href={'../api/' + type + '/' + file.uid}
                className="right-margin-small"
                key={'filetext' + i}
              >
                {file.name}
              </a>
            </div>
          );
        })}
      </div>
    );
  }

  handleReturn(e) {
    e.preventDefault();

    if (this.state.company.inno) {
      this.props.navigate('/adminInno');
    } else {
      this.props.navigate('/adminES');
    }
  }

  acceptReturn() {
    if (this.state.company.inno) {
      this.props.navigate('/adminInno');
    } else {
      this.props.navigate('/adminES');
    }
  }

  render() {
    var {user, logo, rejected, company, admin} = this.state;

    if (this.state.loading) {
      return (
        <div className="mt-4">
          <div className="text-center px-2 limit-width-and-center">
            <div name="form-header" className="bottom-margin">
              <h1 className="bottom-margin-small caps">
                {' '}
                <FormattedMessage id="loading" />{' '}
              </h1>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="text-center mt-4 px-2 limit-width-and-center">
        <div
          style={{float: 'left', clear: 'both'}}
          ref={(scrollertop) => {
            this.resultStart = scrollertop;
          }}
        ></div>

        <div name="form-header" className="bottom-margin">
          {company.inno ? (
            <h1 className="bottom-margin-small">
              {' '}
              <FormattedMessage id="innoProfileApproval" />{' '}
            </h1>
          ) : (
            <h1 className="bottom-margin-small">
              {' '}
              <FormattedMessage id="expertProfileApproval" />{' '}
            </h1>
          )}
        </div>

        <div className="text-start">
          <h2>{company.name}</h2>
          <div name="basicInfoSection" className="bottom-margin">
            <h5 className="bottom-margin-small">
              {' '}
              <FormattedMessage id="basicInfo" />{' '}
            </h5>
            <div className="bottom-margin-small">
              <Row>
                <Col sm={{size: 'auto', offset: 0}} className="input-label">
                  <FormattedMessage id="email" />
                </Col>
                <FormText color="muted" className="caps">
                  {' '}
                  <FormattedMessage id="hiddenOnPublic" />{' '}
                </FormText>
              </Row>
              {user && user.email ? (
                <FormText color="muted" className="font-weight-bold">
                  {user.email}
                </FormText>
              ) : null}
            </div>
            <div className="bottom-margin-small">
              <Row>
                <Col sm={{size: 'auto', offset: 0}} className="input-label">
                  <FormattedMessage id="profileStatus" />
                </Col>
                <FormText color="muted" className="caps">
                  {' '}
                  <FormattedMessage id="hiddenOnPublic" />{' '}
                </FormText>
              </Row>
              {company.accepted ? 'Approved' : 'Pending'}
            </div>
            <div className="bottom-margin-small">
              <Row>
                <Col sm={{size: 'auto', offset: 0}} className="input-label">
                  <FormattedMessage id="businessId" />
                </Col>
              </Row>
              {company.businessId}
            </div>
            <div className="bottom-margin-small">
              <Row>
                <Col className="input-label">
                  <FormattedMessage id="name" />
                </Col>
              </Row>
              {company.name}
            </div>
            {company.unit ? (
              <div className="bottom-margin-small">
                <Row>
                  <Col className="input-label">
                    <FormattedMessage id="unit" />
                  </Col>
                </Row>
                {company.unit}
              </div>
            ) : null}
            <div className="bottom-margin-small">
              <Row>
                <Col className="input-label">
                  <FormattedMessage id="country" />
                </Col>
              </Row>
              {company.country ? (
                <FormattedMessageHOC {...{id: company.country}} />
              ) : null}
            </div>
            <div className="bottom-margin-small">
              <Row>
                <Col className="input-label">
                  <FormattedMessage id="city" />
                </Col>
              </Row>
              {company.city}
            </div>
            <div className="bottom-margin-small">
              <Row>
                <Col className="input-label">
                  <FormattedMessage id="description" />
                </Col>
              </Row>
              <div className="showEnter">{company.description} </div>
            </div>
            <div className="bottom-margin-small">
              {logo ? (
                <div>
                  <Row>
                    <Col className="input-label">
                      <FormattedMessage id="logo" />
                    </Col>
                  </Row>
                  <img width="20%" height="" src={logo} />{' '}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div name="servicesSection" className="bottom-margin">
            <h5>
              {' '}
              <FormattedMessage id="servicesHeader" />{' '}
            </h5>
            <div className="bottom-margin-small">
              <Row>
                <Col className="input-label">
                  <FormattedMessage id="services" />
                </Col>
              </Row>
              {this.renderSelections(company.services)}
            </div>
            {company.descriptionServices ? (
              <div className="bottom-margin-small">
                <Row>
                  <Col className="input-label">
                    <FormattedMessage id="description" />
                  </Col>
                </Row>
                <div className="showEnter"> {company.descriptionServices}</div>
              </div>
            ) : null}
            {company.industries && company.industries.length > 0 ? (
              <div className="bottom-margin-small">
                <Row>
                  <Col className="input-label">
                    <FormattedMessage id="industries" />
                  </Col>
                </Row>
                {this.renderSelections(company.industries)}
              </div>
            ) : null}
            {company.descriptionIndustries ? (
              <div className="bottom-margin-small">
                <Row>
                  <Col className="input-label">
                    <FormattedMessage id="description" />
                  </Col>
                </Row>
                <div className="showEnter">{company.descriptionIndustries}</div>
              </div>
            ) : null}
            {company.countries &&
            company.countries.length > 0 &&
            company.countries[0] !== '' ? (
              <div className="bottom-margin-small">
                <Row>
                  <Col className="input-label">
                    <FormattedMessage id="countries" />
                  </Col>
                </Row>
                {this.renderSelections(company.countries)}
              </div>
            ) : null}
            {company.descriptionCountries ? (
              <div className="bottom-margin-small">
                <Row>
                  <Col className="input-label">
                    <FormattedMessage id="description" />
                  </Col>
                </Row>
                <div className="showEnter">{company.descriptionCountries}</div>
              </div>
            ) : null}
            {company.serviceLanguage ? (
              <div className="bottom-margin-small">
                <Row>
                  <Col className="input-label">
                    <FormattedMessage id="serviceLanguage" />
                  </Col>
                </Row>
                {company.serviceLanguage}
              </div>
            ) : null}
          </div>
          <div name="companySection" className="bottom-margin">
            {company.established ||
            company.turnover ||
            company.employeeCount ||
            company.membership ? (
              <div>
                {' '}
                <h5>
                  {' '}
                  <FormattedMessage id="companyInfo" />{' '}
                </h5>
                {company.established ? (
                  <div className="bottom-margin-small">
                    <Row>
                      <Col className="input-label">
                        <FormattedMessage id="established" />
                      </Col>
                    </Row>
                    {company.established}
                  </div>
                ) : null}
                {company.turnover ? (
                  <div className="bottom-margin-small">
                    <Row>
                      <Col className="input-label">
                        <FormattedMessage id="turnover" />
                      </Col>
                    </Row>
                    {company.turnover}
                  </div>
                ) : null}
                {company.employeeCount ? (
                  <div className="bottom-margin-small">
                    <Row>
                      <Col className="input-label">
                        <FormattedMessage id="employeeCount" />
                      </Col>
                    </Row>
                    {company.employeeCount}
                  </div>
                ) : null}
                {company.membership ? (
                  <div className="bottom-margin-small">
                    <Row>
                      <Col className="input-label">
                        <FormattedMessage id="membership" />
                      </Col>
                    </Row>
                    {company.membership}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div name="addressSection" className="bottom-margin">
            {company.address ? (
              <div>
                <h5>
                  {' '}
                  <FormattedMessage id="addressHeader" />{' '}
                </h5>
                <div className="bottom-margin-small">{company.address}</div>
              </div>
            ) : null}
          </div>

          {company.webpage || company.facebook || company.linkedin ? (
            <div name="linksSection" className="bottom-margin">
              <h5>
                {' '}
                <FormattedMessage id="links" />{' '}
              </h5>
              <div className="bottom-margin-small">
                {company.webpage ? (
                  <span>
                    <a href={company.webpage} target="_blank">
                      <FormattedMessage id="webpage" />
                    </a>
                    <br />
                  </span>
                ) : null}
                {company.facebook ? (
                  <span>
                    <a href={company.facebook} target="_blank">
                      <FormattedMessage id="facebook" />
                    </a>
                    <br />
                  </span>
                ) : null}
                {company.linkedin ? (
                  <span>
                    <a href={company.linkedin} target="_blank">
                      <FormattedMessage id="linkedin" />
                    </a>
                  </span>
                ) : null}
              </div>
            </div>
          ) : null}

          <div name="contactPersonsSection" className="bottom-margin">
            <h5>
              {' '}
              <FormattedMessage id="contactPerson" />{' '}
            </h5>
            {this.renderContactPersons(company.experts)}
          </div>
          <div name="otherSection" className="bottom-margin">
            {(company.publications &&
              company.publications.length > 0 &&
              company.publications[0] !== '') ||
            (company.cvs && company.cvs.length > 0 && company.cvs[0] !== '') ||
            (company.references &&
              company.references.length > 0 &&
              company.references[0] !== '') ? (
              <div>
                <h5>
                  {' '}
                  <FormattedMessage id="relatedMaterials" />{' '}
                </h5>
                {company.publications &&
                company.publications.length > 0 &&
                company.publications[0] !== '' ? (
                  <div className="bottom-margin-small">
                    <Row>
                      <Col className="input-label">
                        <FormattedMessage id="publications" />
                      </Col>
                    </Row>
                    {this.renderPublications(company.publications)}
                  </div>
                ) : null}
                {company.cvs &&
                company.cvs.length > 0 &&
                company.cvs[0] !== '' ? (
                  <div className="bottom-margin-small">
                    <Row>
                      <Col
                        sm={{size: 'auto', offset: 0}}
                        className="input-label"
                      >
                        <FormattedMessage id="cvs" />
                      </Col>
                      <FormText color="muted" className="caps">
                        {' '}
                        <FormattedMessage id="hiddenOnPublic" />{' '}
                      </FormText>
                    </Row>
                    {this.renderFiles(company.cvs, 'cv')}
                  </div>
                ) : null}
                {company.references &&
                company.references.length > 0 &&
                company.references[0] !== '' ? (
                  <div className="bottom-margin">
                    <Row>
                      <Col
                        sm={{size: 'auto', offset: 0}}
                        className="input-label"
                      >
                        <FormattedMessage id="referenceCases" />
                      </Col>
                      <FormText color="muted" className="caps">
                        {' '}
                        <FormattedMessage id="hiddenOnPublic" />{' '}
                      </FormText>
                    </Row>
                    {this.renderFiles(company.references, 'reference')}
                  </div>
                ) : null}{' '}
              </div>
            ) : null}
            <div className='jumbotron-1'>
              <h4>
                {' '}
                <FormattedMessage id="profileApprovalHeader" />{' '}
              </h4>
              <p>
                <FormattedMessage id="markEvaluatedHelper" />
              </p>
              <Row className="mb-2">
                <Col sm="6" className="mb-2">
                  <Button
                    block
                    disabled={
                      this.state.loading ||
                      (company.accepted === true &&
                        company.acceptedTime != null)
                    }
                    color="primary"
                    onClick={this.approveCompany.bind(this, company)}
                  >
                    {this.state.loading ? (
                      <FormattedMessage id="loading" />
                    ) : (
                      <FormattedMessage id="approvePublishProfile" />
                    )}
                  </Button>
                </Col>
                <Col sm="5">
                  <Button
                    block
                    disabled={this.state.loading || rejected}
                    onClick={this.rejectCompany.bind(this, company)}
                  >
                    {this.state.loading ? (
                      <FormattedMessage id="loading" />
                    ) : (
                      <FormattedMessage id="rejectProfile" />
                    )}
                  </Button>
                </Col>
              </Row>
              {rejected ? (
                <RequestInfoSection
                  company={company}
                  user={admin}
                  sendSuccess={this.redirectToList.bind(this)}
                />
              ) : null}
              <Row className="mb-2">
                <Col sm="6" className="mb-2">
                  <Button
                    block
                    id="deletePopover"
                    disabled={this.state.loading}
                    color="danger"
                    onClick={this.toggleDelete.bind(this)}
                  >
                    <FormattedMessage id="deleteProfile" />
                  </Button>
                  <Popover
                    placement="bottom"
                    isOpen={this.state.deletePopoverOpen}
                    target="deletePopover"
                    toggle={this.toggleDelete.bind(this)}
                  >
                    <div ref={this.deletePopoverRef}>
                    <PopoverHeader>
                      <FormattedMessage id="verificationTitle" />
                    </PopoverHeader>
                    <PopoverBody>
                      <FormattedMessage id="verificationDescription" />
                      <Button
                        className="mt-2"
                        block
                        disabled={this.state.loading}
                        color="danger"
                        onClick={this.deleteCompany.bind(this, company)}
                      >
                        <FormattedMessage id="deleteProfile" />
                      </Button>
                    </PopoverBody>
                    </div>
                  </Popover>
                </Col>
                <Col sm="5">
                  {user && user.uid ? (
                    <a
                      style={{width: '100%'}}
                      type="button"
                      className="btn btn-secondary"
                      href={'../#/register/' + user.uid}
                    >
                      {' '}
                      <FormattedMessage id="editProfile" />{' '}
                    </a>
                  ) : null}
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <a href="#" onClick={this.handleReturn.bind(this)}>
                  {company.inno ? (
                    <FormattedMessage id="backToListViewInno" />
                  ) : (
                    <FormattedMessage id="backToListViewES" />
                  )}{' '}
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default _.flowRight(injectIntl, RouterHOC)(CompanyView);
