import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem} from 'reactstrap';
import logo from '../../../public/images/expert_search_logo.svg';
import bflogo from '../../../public/images/bf_logo_blue_rgb.png'
import {NavLink} from 'react-router-dom';
import burger from '../../../public/images/burger.svg';

class Header extends React.Component {
	constructor(props) {
		super(props);

		this.state = { isOpen: false }
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	close() {
		this.setState({
			isOpen: false
		});
	}

	render() {
		return (
			<Navbar expand='sm' className='p-0'>
				<NavbarToggler right="true" onClick={this.toggle.bind(this)} className='m-2'>
					<img src={burger} alt='menu' />
				</NavbarToggler>
				<NavbarBrand className='headerlogo px-4 pt-2'>
					<div><img src={bflogo} alt='Business Finland' style={{ width: "80px", paddingBottom: "10px" }} /></div>
					<div><img src={logo} alt='Expert Search' /></div>
				</NavbarBrand>
				<Collapse isOpen={this.state.isOpen} className='collapsebox' navbar>
					<Nav navbar style={{ width: '100%' }} className='justify-content-start'>
						<NavItem className='pt-2 me-4'>
							<a href='../#/home' className='nav-link'>
								<FormattedMessage id='home' />
							</a>
						</NavItem>
						<NavItem className='pt-2 me-4'>
							<NavLink to='/' className='nav-link' onClick={this.close.bind(this)}>
								<FormattedMessage id='adminNav' />
							</NavLink>
						</NavItem>
						<NavItem className='pt-2 me-4'>
							<a href='../#/about' className='nav-link'>
								<FormattedMessage id='about' />
							</a>
						</NavItem>
						<NavItem className='pt-2'>
							<a href='../#/howto' className='nav-link'>
								<FormattedMessage id='howToExpertHeader' />
							</a>
						</NavItem>
						<NavItem className='pt-2 pe-2 ml-auto'>
							<a href='../#/confirm' className='nav-link'>
								<FormattedMessage id='registerExpert' />
							</a>
						</NavItem>
					</Nav>
				</Collapse>
			</Navbar>
		)
	}

}

export default Header;
