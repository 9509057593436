import React from 'react';
import {createRoot} from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
//import 'react-select/dist/react-select.css';
import CompaniesList from './components/CompaniesList';
import InnoList from './components/InnoList';
import Administration from './components/Administration';
import CompanyView from './components/CompanyView';
import App from './components/App';
import {HashRouter, Navigate, Route, Routes} from 'react-router-dom';

import {IntlProvider} from 'react-intl';
import localeData from '../../i18n/data.json';

class Private extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<HashRouter>
				<App>
					<Routes>
						<Route exact path="/" element={<Navigate replace to="/admin" />} />
						<Route path="/admin" element={<Administration />} />
						<Route path='/adminES' element={<CompaniesList />} />
						<Route path='/adminInno' element={<InnoList />} />
						<Route path='/:id' element={<CompanyView />} />
					</Routes>
				</App>
			</HashRouter>
		);
	}

}

const privateContainer = document.getElementById('private');
if (privateContainer) {
	const privateRoot = createRoot(privateContainer);
	privateRoot.render(<IntlProvider locale='en' messages={localeData['en']} >
		<Private />
	</IntlProvider>);
}